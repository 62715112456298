@use './variables' as *;

table {

  th {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
    font-weight: bold !important;
  }

  td {
    padding: 0.5rem, 0 !important;
  }

}
