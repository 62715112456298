@use '../../scss/colors' as *;

.wrapper {
    display: flex;
    justify-content: center;
    align-items: center;

    #pricing-calculator {
        border: 1px solid $white-dark;
        border-radius: 0.25rem;
        background-color: $light-grey;
        margin: 1rem;
        padding: 0.5rem 1rem;
        max-width: 700px;

        h1 {
            color: $bezos-blue;
            font-weight: 900;
        }
    
        h2 {
           font-size: small;
           font-weight: 900;
        }
    
        .slider-value {
            color: $bezos-green;
            font-size: xx-large;
            font-weight: 900;
            position: relative;     
            bottom: 5px;  
        }
        
        .per-order-price {
            font-size: xx-large;
        }
    
        button {
            float: right;
        }
    
        .success {
            color: $bezos-green;
            font-size: xx-large;
            font-weight: 900;
        }
    
        .error {
            color: $red;
            font-size: xx-large;
            font-weight: 900;
        }
    
        .italic {
            font-style: italic;
        }
    
        .centered-box {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
          }
    
        .voucher {
            background-color: $bezos-green;
            color: $white;
            text-align: center;

            .wrap {
                border: 3px dashed $white;
                padding: 0.25rem 2rem;

                h2 {
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    font-size: 1.5rem;
                    font-weight: 900;
                    text-transform: uppercase;
                    line-height: 125%;
                    margin: 0.5rem 0;
                }

                p {
                    font-size: 0.75rem;
                    font-weight: 500;
                }

            }

        };

    }

}
