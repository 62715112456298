@use './variables' as *;
@use './colors' as *;
@use './tables' as *;

body {
  min-height: 100vh;
  margin: 0;
  font-family: $font-default;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: $font-size-base;
  font-weight: $font-weight-base;
  background-color: $white;
}

em {
  font-style: normal;
  font-weight: $font-weight-heavy;
  }
